import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import MainBg from "../components/mainBg";
import Callout from "../components/callout";
import DidYouKnow from "../components/didYouKnow";
import NewsletterSignup from "../components/newsletterSignup";
import CallToAction from "../components/callToAction";

class GroupLifeInsurance extends React.Component {
  render() {
    
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Group Health Insurance - Baton Rouge | Lewis Mohr"
          meta={[{ name: "description", content: "A group health insurance plan is a health insurance plan that provides coverage to members of a group that tends to be employees of a company or members of an organization. Contact us to get an insurance quote for your Louisiana based business." }]}  
        />
        <MainBg mainBg={this.props.data.mainBg} alt="Group meeting" />
        <Callout
          title="Group Health Insurance"
          content="A group health insurance plan is a health insurance plan that provides coverage to members of a group that tends to be employees of a company or members of an organization. Members of the group usually receive insurance at a reduced cost because the insurer’s risk is spread across a group of policyholders."
        />
        <div className="site-body page-content">
          <h2>Benefits of a Group Health Insurance Plan</h2>
          <p>
          <ul>
          <li><strong>Risk pool advantages.</strong> Group health insurance policies help spread risk across a pool of insured individuals. This benefits the group members by keeping premiums low, and insurers can better manage risk when they have a clearer idea of who they are covering. </li>
          <li><strong>Tax benefits for employees.</strong> In addition to benefiting from employer contributions, employees are usually paying for their part of the premium with pre-tax money, which can add up to significant savings.</li>
          <li><strong>Tax benefits for employers.</strong> There are perks to being a small business owner and providing small business group health insurance. You will likely pay at least fifty percent of the monthly premiums for your employees, but you may be able to deduct these costs from your business taxes.</li>
          </ul>
          </p>
          <DidYouKnow
            fact="The average small business plan deductible was 40% lower than the average deductible on an individual plan."
            source="2017 eHealth Small Business Health Insurance Report"
          />
          <h2>Why Small Businesses Offer Group Health Insurance</h2>
          <p>
          <ul>
          <li><strong>Business Strategy.</strong>  64% of businesses say they offer health insurance coverage because it helps them hire and retain talent.</li>
          <li><strong>Responsibility For Employees.</strong> Over 40% of businesses said they felt morally obligated to offer health insurance to help their employees provide for their family.</li>
          </ul>
          </p>
        </div>
        <CallToAction bg={this.props.data.callToActionBg} type="a Group Health Insurance" />
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default GroupLifeInsurance;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "group-health-insurance-bg.jpg" }) {
      ...mainBg
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-7.jpg" }) {
      ...mainBg
    }
  }
`;
